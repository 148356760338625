import React, { useEffect } from "react";
import styled from "styled-components";
import Spacer from "../_global/layout/Spacer";
import "./tamaro.css";
import PillButton from "../_global/buttons/PillButton";

const Comp = styled.div`
  align-self: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${p => p.theme.margins.small.l};
  margin-right: ${p => p.theme.margins.small.l};
  /* background-color: ${({ theme }) => theme.colors.titanWhite}; */

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    margin-left: ${p => p.theme.margins.small.s};
    margin-right: ${p => p.theme.margins.small.s};
  }
`;

const TamaroForm = styled.div`
  max-width: ${({ theme }) => theme.sizes.maxWidth};
`;

const Tamaro = (props) => {

	useEffect(() => {
		const script = document.createElement("script");

		script.src = "https://tamaro.raisenow.com/hyfcx/latest/widget.js";
		script.async = true;
		const child = document.body.appendChild(script);

		child.addEventListener("load", () => {
			// RNW is loaded
			if (window.rnw) {
				window.rnw.tamaro.runWidget(".rnw-widget-container", {
					language: "fr"/*,
					showStoredCustomerEmailPermission: true,
					// debugSlots: true,
					// debug: true,
					// debugStyles: true,
					purposes: [
						"p1",
						"p2",
						"p3",
						"p4"
					],
					paymentFormPrefill: {
						purpose_custom: null
					},
					paymentWidgetBlocks: [
						"payment_purposes",
						"payment_amounts_and_intervals",
						"payment_payment_methods",
						"payment_profile",
						{
							"if": "showPaymentAddress()",
							"then": "payment_address"
						}
					],
					translations: {
						en: {
							payment_form: {
								purpose_custom: "You can specify if you wish to donate for a particular project or activity.",
								stored_customer_email_permission: "I would like to be informed about other projects"
							},
							purposes: {
								p1: "The foundation",
								p2: "The families in need fund",
								p3: "An activity",
								p4: "A research project"
							}
						},
						fr: {
							payment_form: {
								purpose_custom: "Vous pouvez préciser si vous souhaitez donner pour un projet ou une activité particulière.",
								stored_customer_email_permission: "Veuillez me tenir informé·e"
							},
							purposes: {
								p1: "La fondation",
								p2: "Le fond de soutien aux familles dans le besoin",
								p3: "Une activité",
								p4: "Un projet"
							},
							blocks: {
								payment_purposes: {
									title: "Motif de mon don"
								}
							}
						}
					},
					slots: {
						purposes_end: [{
							component: "field",
							type: "text",
							name: "purpose_custom",
							label: "payment_form.purpose_custom",
							placeholder: ""
						}]
					}*/
				});
			}
		});

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<Comp>
			<Spacer s="large" />
			<PillButton as="a" href="https://donate.raisenow.io/hyfcx?lng=fr" target="_blank" rel="noopener noreferrer">Je fais une donation</PillButton>
			{/* <TamaroForm className="rnw-widget-container" /> */}
			<Spacer s="large" />
		</Comp>
	);
};

export default Tamaro;

import React from "react";
import Layout from "../components/_global/layout/Layout";
import Tamaro from "../components/2_donate/tamaro";
import localize from "../components/_global/localization/localize";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Block from "../components/_global/editorial/Block";
import Section from "../components/_global/layout/Section";
import Spacer from "../components/_global/layout/Spacer";
import DonateReasons from "../components/2_donate/DonateReasons";

const Donate = ({ data, ...props }) => {
	const donateData = data.sanityDonate;

	return (
		<Layout title={donateData.common.title}>
			<PageHeader title={donateData.common.title} />

			<Section m="large">
				<Block data={donateData.headText1} />
				<Spacer s="medium" />

				<DonateReasons data={donateData.reasons} />
				<Spacer s="medium" />

				<Block data={donateData.headText2} />
				<Spacer s="large" />
			</Section>

			<Tamaro />
			<Spacer s="large" />

			<Section m="large">
				<Block data={donateData.bottomText} />
				<Spacer s="large" />
			</Section>
		</Layout>
	);
};

export default localize(Donate);

export const query = graphql`
    {
        sanityDonate {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            reasons {
                text {
                    _type
                    fr
                }
                title {
                    _type
                    fr
                }
            }
            headText1 {
                ...RegularBlockFragment
            }
            headText2 {
                ...RegularBlockFragment
            }
            bottomText {
                ...RegularBlockFragment
            }
        }
    }
`;
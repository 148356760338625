import styled from "styled-components";

const PillButton = styled.div`
  background-color: ${p => p.disabled ? p.theme.colors.periwinkleGray : p.theme.colors.main01};
  color: ${p => p.disabled ? p.theme.colors.background : p.theme.colors.background};
  border: 2px solid ${p => p.disabled ? p.theme.colors.periwinkleGray : p.theme.colors.main01};
  border-radius: 99rem;
  padding: 1.25rem;
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.buttonSmall.size.regular};
  font-family: ${({ theme }) => theme.text.buttonSmall.family};
  cursor: ${p => p.disabled ? "default" : "pointer"} !important;
  max-width: 100%;
  transition: all 0.3s ease 0s;
	text-align: center;

  :hover {
    background-color: ${p => p.disabled ? p.theme.colors.periwinkleGray : p.theme.colors.background};
    color: ${p => p.disabled ? p.theme.colors.background : p.theme.colors.main01};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
      font-size: ${({ theme }) => theme.text.buttonSmall.size.small};
    //font-size: 12rem;
    padding: 1.25rem 0.5rem;
		
  }
`;

export default PillButton;

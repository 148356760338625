import React from "react";
import styled from "styled-components";
import Text from "../_global/editorial/Text";

const Comp = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    display: flex;
    flex-direction: column;
  }
`;

const Reason = styled.div`
  background-color: ${({ theme }) => theme.colors.titanWhite};
  padding: 1.25rem;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    padding: 1rem;
  }
`;

const DonateReasons = (props) => {
	const { data } = props;
	const reasons = data.map((item, index) => {
		return (
			<Reason key={index}>
				<Text s="medium" w="bold" as="span">
					{item.title}
				</Text>
				&nbsp;
				<Text s="medium" as="span">
					{item.text}
				</Text>
			</Reason>
		);
	});

	return (
		<Comp>
			{reasons}
		</Comp>
	);
};

export default DonateReasons;